// DonationsByAge.js
import React, { useContext } from 'react';
import { DataContext } from '../DataContext';

function DonationsByAge() {
  const { totalByAge } = useContext(DataContext);

  const ageGroups = totalByAge.map((group, index) => ({
    ...group,
    label2: getLabelByIndex(index),
  }));

  const minAmount = Math.min(...ageGroups.map((group) => group.amount));
  const maxAmount = Math.max(...ageGroups.map((group) => group.amount));
  const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

  function getLabelByIndex(index) {
    const labels = ['Al-Atfal', 'Al-Shabab', 'Fiqh Finders', 'Wise Walis', 'Mature Muftis', 'Veteran Viziers'];
    return labels[index] || '';
  }

  // Calculate the total amount for unspecified age category
  const unspecifiedAmount = totalByAge.reduce((sum, group) => {
    if (group.label === '' || group.label === null) {
      return sum + group.amount;
    }
    return sum;
  }, 0);

  // Filter out the unspecified age groups
  const specifiedAgeGroups = ageGroups.filter((group) => group.label !== '' && group.label !== null);

  return (
    <div className="donations-by-age">
      <h2>Donations by Age</h2>
      <div className="bubble-chart">
        {specifiedAgeGroups.map((group, index) => (
          <div key={index} className="bubble-item">
            <div
              className="bubble"
              style={{
                width: `${(group.amount / maxAmount) * 150}px`,
                height: `${(group.amount / maxAmount) * 150}px`,
                backgroundColor: colors[index % colors.length],
              }}
            ></div>
            <div className="label">{group.label}</div>
            <div className="label2">{group.label2}</div>
            <div className="amount">${group.amount.toLocaleString()}</div>
          </div>
        ))}
        {unspecifiedAmount > 0 && (
          <div className="bubble-item">
            <div
              className="bubble"
              style={{
                width: `${(unspecifiedAmount / maxAmount) * 150}px`,
                height: `${(unspecifiedAmount / maxAmount) * 150}px`,
                backgroundColor: '#CCCCCC',
              }}
            ></div>
            <div className="label2">Unspecified</div>
            <div className="amount">${unspecifiedAmount.toLocaleString()}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DonationsByAge;