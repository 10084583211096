// DonationsByGender.js
import React, { useContext } from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { DataContext } from '../DataContext';

function DonationsByGender() {
  const { totalByGender } = useContext(DataContext);

  const data = totalByGender.map((gender) => ({
    name: gender.label,
    value: gender.amount,
  }));

  const COLORS = ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        <tspan x={x} dy="-0.8em">{name}</tspan>
        <tspan x={x} dy="1.2em">{`$${(percent * totalByGender.reduce((sum, gender) => sum + gender.amount, 0)).toLocaleString()}`}</tspan>
      </text>
    );
  };

  return (
    <div className="donations-by-gender">
      <h2>Donations by Gender</h2>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={120}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DonationsByGender;