// addPledge.js
import { collection, addDoc } from 'firebase/firestore';
import db from './firebase-config';

const addPledge = async (pledgeData) => {
  try {
    const { firstName, lastName, email, phoneNumber, location, gender, ageCategory, amount, category } = pledgeData;

    // Create a new pledge object with the updated structure
    const newPledge = {
      firstName,
      lastName,
      email: email || null,
      phoneNumber: phoneNumber || null,
      location,
      gender,
      ageCategory: ageCategory || null,
      amount,
      category,
    };

    const pledgesRef = collection(db, 'pledges');
    const newPledgeRef = await addDoc(pledgesRef, newPledge);
    console.log('Pledge added with ID:', newPledgeRef.id);
  } catch (error) {
    console.error('Error adding pledge:', error);
  }
};

export default addPledge;