// DonationsByLocation.js
import React, { useContext } from 'react';
import { DataContext } from '../DataContext';

function DonationsByLocation() {
  const { totalByLocation } = useContext(DataContext);

  const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

  return (
    <div className="donations-by-location">
      <h2>Donations by Location</h2>
      <table>
        <thead>
          <tr>
            <th className="location-column">Location</th>
            <th>Total Amount</th>
            <th>Number of Donations</th>
          </tr>
        </thead>
        <tbody>
          {totalByLocation.map((location, index) => (
            <tr key={index}>
              <td className="location-column" style={{ backgroundColor: COLORS[index % COLORS.length] }}>
                {location.label}
              </td>
              <td>${location.amount.toLocaleString()}</td>
              <td>{location.donations}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DonationsByLocation;