// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import TotalAmount from './components/TotalAmount';
import AmountByCategory from './components/AmountByCategory';
import DonationsByGender from './components/DonationsByGender';
import DonationsByAge from './components/DonationsByAge';
import DonationsByLocation from './components/DonationsByLocation';
import PledgePage from './components/PledgePage';
import { DataProvider } from './DataContext';
import './App.css';

function App() {
  return (
    <Router>
      <DataProvider>
        <div className="app">
          <div className="pledge-button-container">
            <Link to="/pledge" className="pledge-button">Pledge Now</Link>
          </div>
          <Routes>
            <Route path="/" element={
              <>
                <h1>MCMC Fundraiser Dashboard</h1>
                <div className="dashboard">
                  <TotalAmount />
                  <AmountByCategory />
                  <DonationsByGender />
                  <DonationsByLocation />
                  <DonationsByAge />
                </div>
              </>
            } />
            <Route path="/pledge" element={<PledgePage />} />
          </Routes>
        </div>
      </DataProvider>
    </Router>
  );
}

export default App;