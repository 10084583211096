// DataContext.js
import React, { createContext, useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import db from './firebase-config';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    totalAmount: 0,
    goalAmount: 0,
    numberOfDonations: 0,
    totalByCategory: [],
    totalByAge: [],
    totalByGender: [],
    totalByLocation: [],
  });

  useEffect(() => {
    const summaryRef = doc(db, 'summary', 'summary');
    const unsubscribe = onSnapshot(summaryRef, (snapshot) => {
      if (snapshot.exists()) {
        setData(snapshot.data());
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};