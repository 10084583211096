// PledgePage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import addPledge from '../addPledge';

function PledgePage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [location, setLocation] = useState('2nd floor');
  const [gender, setGender] = useState('Male');
  const [ageCategory, setAgeCategory] = useState('');
  const [amount, setAmount] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation check
    if (!email && !phoneNumber) {
      setErrorMessage('Please provide either an email address or a phone number.');
      return;
    }

    const numericAmount = Math.round(parseFloat(amount.replace(/,/g, '')));
    if (isNaN(numericAmount)) {
      setErrorMessage('Please enter a valid numeric amount.');
      return;
    }

    const category = getCategory(numericAmount);
    const pledgeData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      location,
      gender,
      ageCategory,
      amount: numericAmount,
      category,
    };


    await addPledge(pledgeData);

    // Reset the form
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setLocation('2nd floor');
    setGender('Male');
    setAgeCategory('');
    setAmount('');
    setErrorMessage('');

    // Show the confirmation message
    setShowConfirmation(true);

    // Navigate back to the main page after 3 seconds
    setTimeout(() => {
      navigate('/');
    }, 3000);
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      const roundedValue = Math.round(numericValue);
      const formattedValue = roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setAmount(formattedValue);
    } else {
      setAmount('');
    }
  };

  const getCategory = (amount) => {
    if (amount < 100) return '1+';
    if (amount < 500) return '100+';
    if (amount < 1000) return '500+';
    if (amount < 2500) return '1,000+';
    if (amount < 5000) return '2,500+';
    if (amount < 10000) return '5,000+';
    if (amount < 25000) return '10,000+';
    if (amount < 50000) return '25,000+';
    return '50,000+';
  };

  const ageCategories = [
    'Under 18',
    '19-24',
    '25-39',
    '40-55',
    '56-64',
    '65+',
  ];

  const locations = [
    '2nd floor',
    '1st floor',
    'gym',
    'Online/Other',
  ];

  return (
    <div className="pledge-page">
      <h2>Pledge Now</h2>
      {showConfirmation ? (
        <div className="confirmation-message">
          Thank you for your pledge! May Allah reward you for supporting MCMC!! Redirecting to the main page...
        </div>
      ) : (
        <>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="amount">Pledge Amount:</label>
              <input
                type="text"
                id="amount"
                value={amount}
                onChange={handleAmountChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="firstName">First Name:</label>
              <input
                type="text"
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name:</label>
              <input
                type="text"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone Number:</label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Location:</label>
              <div>
                {locations.map((loc) => (
                  <label key={loc}>
                    <input
                      type="radio"
                      value={loc}
                      checked={location === loc}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                    />
                    {loc}
                  </label>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label>Gender:</label>
              <div>
                <label>
                  <input
                    type="radio"
                    value="Male"
                    checked={gender === 'Male'}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  Male
                </label>
                <label>
                  <input
                    type="radio"
                    value="Female"
                    checked={gender === 'Female'}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  Female
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="ageCategory">Age Category (Optional):</label>
              <select
                id="ageCategory"
                value={ageCategory}
                onChange={(e) => setAgeCategory(e.target.value)}
              >
                <option value="">Select Age Category</option>
                {ageCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="general-message">Allah is watching. Please hit submit only if you intend to donate.</div>
            <button type="submit" className="submit-button">Submit Pledge</button>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
          </form>
          <button className="back-button" onClick={handleGoBack}>Go Back</button>
        </>
      )}
    </div>
  );
}

export default PledgePage;