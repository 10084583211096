// AmountByCategory.js
import React, { useContext } from 'react';
import { DataContext } from '../DataContext';

function AmountByCategory() {
  const { totalByCategory } = useContext(DataContext);

  return (
    <div className="amount-by-category">
      <h2>Amount Collected by Category</h2>
      <table>
        <thead>
          <tr>
            <th className="category-column">Category</th>
            <th>Total Amount</th>
            <th>Number of Donations</th>
          </tr>
        </thead>
        <tbody>
          {totalByCategory.map((category, index) => (
            <tr key={index}>
              <td className="category-column">{category.label}</td>
              <td>${category.amount.toLocaleString()}</td>
              <td>{category.donations}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AmountByCategory;