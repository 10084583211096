// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXyeEyx2yjNztkzmOvnRSfgp2Z3wPn4f8",
  authDomain: "mcmcgive.firebaseapp.com",
  projectId: "mcmcgive",
  storageBucket: "mcmcgive.appspot.com",
  messagingSenderId: "336941547897",
  appId: "1:336941547897:web:7851859ea01cdf7bad6fa8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
