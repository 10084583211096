// TotalAmount.js
import React, { useContext, useRef, useEffect } from 'react';
import CountUp from 'react-countup';
import { DataContext } from '../DataContext';

function TotalAmount() {
  const { totalAmount, goalAmount, numberOfDonations } = useContext(DataContext);
  const prevTotalAmountRef = useRef(0);

  useEffect(() => {
    prevTotalAmountRef.current = totalAmount;
  }, [totalAmount]);

  return (
    <div className="total-amount">
      <h2>Total Amount Collected</h2>
      <p className="amount-text">
        <CountUp
          start={prevTotalAmountRef.current}
          end={totalAmount}
          duration={2}
          separator=","
          prefix="$"
        />
      </p>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${(totalAmount / goalAmount) * 100}%` }}></div>
        <span className="goal-amount">${goalAmount.toLocaleString()}</span>
      </div>
      <p className="total-donations">Total Donations: {numberOfDonations}</p>
    </div>
  );
}

export default TotalAmount;